<template>
<div>
  <b-container>
    <b-row class="mb-4" v-if="!permission">
      <b-col>
        Access denied
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-show="loading">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission">
      <b-col>
        <vue-datamaps :data="countriesData" />
        <b-card class="mb-3" v-if="!loading">
          <div class="mb-2" v-for="source in sources" :key="source.id">
          <div><strong>{{source.source}}</strong></div>
          <div>publisher: {{source.publisher}}</div>
          <div>type: {{source.type}}</div>
          <div>country: {{source.countryName}}</div>
          <div>country code: {{source.countryCode}}</div>
          <div>language: {{source.langName}}</div>
          <div>language code: {{source.langCode}}</div>
          <div>link: <a :href="source.url" target="_blank">{{source.url}}</a></div>
          <div>parser: {{source.parser}}</div>
          </div>
        </b-card>
        <b-card>
          <h4>Add new source</h4>
          <b-form-group label="Publisher">
            <b-form-input v-model="form.publisher"
                  type="text"
                  placeholder="Publisher, e.g.: FCA"></b-form-input>
          </b-form-group>
          <b-form-group label="Source">
            <b-form-input v-model="form.source"
                  type="text"
                  placeholder="Publisher, e.g.: FCA news"></b-form-input>
          </b-form-group>
          <b-form-group label="Type">
            <b-form-input v-model="form.type"
                  type="text"
                  placeholder="Type, e.g.: news"></b-form-input>
          </b-form-group>
          <b-form-group label="URL">
            <b-form-input v-model="form.url"
                  type="text"
                  placeholder="URL http://example.com"></b-form-input>
          </b-form-group>
          <b-form-group label="Parser">
            <b-form-input v-model="form.parser"
                  type="text"
                  placeholder="rss, fca publications, esma news"></b-form-input>
          </b-form-group>
          <b-form-group label="Active">
            <b-form-radio-group id="radiogroup2" v-model="form.active">
              <b-form-radio value="yes">yes</b-form-radio>
              <b-form-radio value="no">no</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group label="Country">
            <b-form-select v-model="form.countryCode" :options="countryOptions"/>
          </b-form-group>
          <b-form-group label="Language">
            <b-form-select v-model="form.langCode" :options="langOptions"/>
          </b-form-group>
          <b-button class="mr-2" v-if="valid" v-on:click="saveSource">Save Source</b-button>
          <b-button class="mr-2" v-if="valid" v-on:click="testRSS">Test RSS</b-button>
          <b-button class="mr-2" v-if="valid && news" v-on:click="saveRSS">Save RSS content</b-button>
          <b-alert :show="dismissCountDown"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged">
            <p>{{msg}}</p>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="news">
      <b-col>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <h4 class="card-title"><a :href="item.link" target="_blank">{{item.name}}</a></h4>
          <div class="mb-2">{{item.source}} - {{item.dateTime}}</div>
          <div class="mb-2">{{item.body}}</div>
          <div class="mb-2">{{item.publisher}}</div>
          <div class="mb-2">{{item.year}} - {{item.quarter}} - {{item.month}} - {{item.week}} </div>
          <div class="mb-2">{{item.link}}</div>
          <div class="mb-2">{{item.dateTime}}</div>
          <div class="mb-2">{{item.countryName}} - {{item.countryCode}} - {{item.langName}} - {{item.langCode}}</div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import countries from '../libs/countries'
import languages from '../libs/languages'
import VueDatamaps from '@/components/datamaps/Datamaps.vue'

export default {
  beforeDestroy () {
  },
  components: {
    VueDatamaps
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    valid: function () {
      return (this.form.url && this.form.type && this.form.source && this.form.publisher && this.form.countryCode && this.form.langCode)
    }
  },
  created: function () {
    this.$stat.log({ page: 'sources', action: 'open sources' })
    this.permission = ac.can(this.user.acgroups).createAny('source').granted
    if (this.permission) {
      this.getSources()
      this.countryOptions[0] = { value: null, text: 'Select country' }
      for (let i = 0, len = countries.length; i < len; i++) {
        this.countryOptions[i + 1] = {}
        this.countryOptions[i + 1].value = countries[i].alpha2
        this.countryOptions[i + 1].text = countries[i].country
      }
      this.langOptions[0] = { value: null, text: 'Select language' }
      for (let i = 0, len = languages.length; i < len; i++) {
        this.langOptions[i + 1] = {}
        this.langOptions[i + 1].value = languages[i].code
        this.langOptions[i + 1].text = languages[i].name
      }
    } else {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      countriesData: {},
      countryOptions: [],
      dismissSecs: 3,
      dismissCountDown: 0,
      form: {
        active: 'yes',
        countryCode: null,
        langCode: null,
        parser: null
      },
      msg: null,
      news: null,
      langOptions: [],
      loading: true,
      permission: false,
      sources: []
    }
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getSources: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/sources'
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('response', response)
        this.sources = _.sortBy(response, 'source')
        this.loading = false
        const countriesData = {}
        let cs = this.sources.map(x => x.countryCode)
        cs = _.uniq(cs)
        cs.forEach(x => {
          const country = _.find(countries, ['alpha2', x])
          const value = { fillKey: 'exists' }
          Object.defineProperty(countriesData, country.alpha3, { value: value, writable: true })
        })
        this.countriesData = countriesData
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    saveRSS: async function () {
      this.$logger.debug('saveRSS started')
      this.form.countryName = _.find(countries, ['alpha2', this.form.countryCode]).country
      try {
        let apiName = 'cosmos'
        let path = '/rss/save'
        let params = { body: { source: this.form } }
        await this.$Amplify.API.post(apiName, path, params)
        this.msg = 'Saved RSS items to database'
        this.dismissCountDown = this.dismissSecs
      } catch (e) {
        this.$logger.warn('testRSS error' + e)
      }
    },
    saveSource: async function () {
      this.$logger.debug('saveSource started')
      this.form.countryName = _.find(countries, ['alpha2', this.form.countryCode]).country
      this.form.langName = _.find(languages, ['code', this.form.langCode]).name
      this.$logger.debug(this.form)
      try {
        let apiName = 'cosmos'
        let path = '/source'
        let params = {
          body: {
            form: this.form
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
        this.form.publisher = null
        this.form.source = null
        this.form.url = null
        this.form.type = null
        this.form.countryCode = null
        this.form.langCode = null
        this.msg = 'Saved source'
        this.dismissCountDown = this.dismissSecs
        this.getSources()
      } catch (e) {
        this.$logger.warn('saving source error' + e)
      }
    },
    testRSS: async function () {
      this.$logger.debug('testRSS started')
      this.form.countryName = _.find(countries, ['alpha2', this.form.countryCode]).country
      try {
        let apiName = 'cosmos'
        let path = '/rss/test'
        let params = { body: { source: this.form } }
        let response = await this.$Amplify.API.post(apiName, path, params)
        console.log(response)
        this.news = response
      } catch (e) {
        this.$logger.warn('testRSS error' + e)
      }
    }
  }
}
</script>

<style>
</style>
